import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { addToCart, updateToken, addMyCart } from "../../actions/index";
import ProductItem from "../products/product-item";
import * as PRODUCTS from "../../api/product";
import { convertToCartItem } from "../../services/index";
import loading from "../../assets/images/loader.gif";
import Countdown from 'react-countdown';
import "./custom-countdown.css?v=1";
class SelectedCategoryProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      productList: [],
      currentTab: 0,
      currentPage: 1,
      moreLoading: false,
      noResult: false
    };
  }

  componentWillMount() {
    this.getProductList();
  }

  // getProductList() {
  //   let params = {
  //     per_page: 12,
  //     page: this.state.currentPage,
  //     category: 1750,
  //     rand: 1
  //   };
  //   PRODUCTS.getSelectedCategoryProducts(params)
  //     .then(res => {
  //       if (res.data) {
  //         this.setState(
  //           {
  //             productList: this.state.currentPage == 1 ? res.data ? res.data : []
  //               : [...this.state.productList, ...res.data],
  //             totalPage: res.pagination.total_page
  //           },
  //           () => {
  //             this.setState({
  //               noResult: false,
  //               isLoading: false,
  //               moreLoading: false
  //             });
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           noResult: true,
  //           isLoading: false,
  //           moreLoading: false
  //         })
  //       }
  //     })
  //     .catch(err => {
  //       this.setState({
  //         isLoading: false,
  //         moreLoading: false
  //       });
  //     });
  // }

  getProductList() {
    let cat = [1674, 1675, 1676, 1687];
    const random = Math.floor(Math.random() * cat.length);
    let params = {
      per_page: 12,
      page: this.state.currentPage,
      id: cat[random],
      include: "child",
    };
    PRODUCTS.getCategoryProductListSpecial(params)
      .then(res => {
        if (res.data) {
          this.setState(
            {
              productList: this.state.currentPage == 1 ? res.data ? res.data : []
                : [...this.state.productList, ...res.data],
              totalPage: res.pagination.total_page
            },
            () => {
              this.setState({
                noResult: false,
                isLoading: false,
                moreLoading: false
              });
            }
          );
        } else {
          this.setState({
            noResult: true,
            isLoading: false,
            moreLoading: false
          })
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          moreLoading: false
        });
      });
  }

  fetchMore() {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
        moreLoading: true
      },
      () => {
        this.getProductList();
      }
    );
  }

  changeTab(tab) {
    if (tab !== this.state.currentTab) {
      this.setState({ currentTab: tab, currentPage: 1, productList: [] }, () => {
        this.getProductList();
      });
    }
  }

  render() {
    const { productList } = this.state;
    const { addToCart, title, subtitle, symbol, history, highlightTitle } = this.props;
    const Completionist = () => <span>You are good to go!</span>;
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      }
    };

    return (
      <div style={{ minHeight: '300px', justifyContent: 'center', paddingBottom: '15px' }} >
        <div className="title1" style={sectionBox}>
          {subtitle ? <h4>{subtitle}</h4> : ""}
          <h2 style={{ marginBottom: "0px" }} className="title-inner1">
            <span className="highlight">{highlightTitle}</span>
            {title}
          </h2>
        </div>
        {this.state.isLoading ? (
          <div className="loading-cls" />
        ) : (
          <section className="section-b-space addtocart_count" style={{ paddingTop: 0 }}>
            <div className="container custom-trending-list">
              <div className="no-slider row product-row">
                {productList.map((product, index) => (
                  <ProductItem
                    product={product}
                    symbol={symbol}
                    updateToken={updateToken}
                    addMyCart={addMyCart}
                    history={history}
                    onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const sectionBox = {
  paddingTop: 25
};

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop: '200px'
};

const getMoreStyle = {
  textAlign: "center",
  fontSize: 20,
  cursor: "pointer",
};

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps, { addToCart, updateToken, addMyCart })(SelectedCategoryProducts);
