import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";
import * as General from "../../../api/general";
import { Facebook } from "react-feather";
import { relativeTimeThreshold } from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      footerList: props.footerMenu,
      // footer info
      address1: "",
      address2: "",
      officeno: "",
      email: "",
      faxno: "",
      mobileno: "",
      mobileView: false,
      whatsapp: "",
      currentYear: null,
      companyName: null,
      companyRegistrationNo: null,
      nowTimeString: '',
    };
  }

  resize() {
    if (window.innerWidth <= 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getGeneralInfo();
    this.getCurrentYear();
    let date = new Date();
    this.setState({ nowTimeString: Date.parse(date) });
  }

  getGeneralInfo() {
    General.getSystemSettingsGeneral()
      .then((res) => {
        this.setState({
          address1: res.data.address1.value,
          address2: res.data.address2.value,
          officeno: res.data.office_no_1.value,
          mobileno: res.data.mobile_no_1.value,
          faxno: res.data.fax_no.value,
          email: res.data.e_mail.value,
          whatsapp: res.data.whatsapp ? res.data.whatsapp.value : '',
          companyName: res.data.company_name.value,
          companyRegistrationNo: res.data.company_no.value
        });

        if (res.data.social.instagram_url) {
          this.setState({
            instagram: res.data.social.instagram_url.value,
          });
        }

        if (res.data.social.tiktok_url) {
          this.setState({
            tiktok: res.data.social.tiktok_url.value,
          });
        }

        if (res.data.social.facebook_url) {
          this.setState({
            facebook: res.data.social.facebook_url.value,
          });
        }

        if (res.data.social.flickr_url) {
          this.setState({
            flickr: res.data.social.flickr_url.value,
          });
        }

        if (res.data.social.youtube_url) {
          this.setState({
            youtube: res.data.social.youtube_url.value,
          });
        }

        if (res.data.social.twitter_url) {
          this.setState({
            twitter: res.data.social.twitter_url.value,
          });
        }
      })

      .catch((err) => {});
  }

  getCurrentYear() {
    var year = new Date().getFullYear();
    this.setState({
      currentYear: year
    })
  }

  render() {
    const { footerMenu } = this.props;
    const whatsappNumber = this.state.whatsapp.replace(/\D/g, '');
    const tiktok = this.state.tiktok
    const facebook = this.state.facebook
    const instagram = this.state.instagram
    const youtube = this.state.youtube
    const twitter = this.state.twitter
    const flickr = this.state.flickr
    return (
      <footer className="footer-light">
        <a hidden={whatsappNumber.length == 0} href={"https://api.whatsapp.com/send?phone=" + whatsappNumber} className="float chat-float" target="_blank">
          <i className="fa fa-whatsapp"></i>
        </a>
        <section className="section-b-space">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-5 col-md-6">
                <div className="footer-title footer-mobile-title">
                  <h4>about</h4>
                </div>
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage from={"footer"} logo={this.props.logoName} />
                  </div>
                  <p>
                    ABOUT<br></br>
                    HarimauFresh<br></br>
                    We provide families with the essentials for your household by sourcing for the freshest and best quality items.
                    Pick from more than 10,000 different types of groceries, fresh produce, dairy, confectionary, household to baby products.
                    We deliver to you fresh, fast and on demand.
                  </p>
                  <br></br>
                  <h6>Partner:</h6>
                  <div className="footer-logo" style={{ marginTop: 25 }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/sri_ternak_logo.png?` + this.state.nowTimeString}
                      alt=""
                      style={{ width: "213px", height: "auto" }}
                    />
                    {cookies.get('partner-referral') ?
                    <>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/my_hub_logo.png?` + this.state.nowTimeString}
                      alt=""
                      style={{ width: "85px", height: "auto", marginLeft: "10px" }}
                    />
                    </> : ''}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>why Choose Us</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      {footerMenu && footerMenu.length > 0
                        ? footerMenu.map((menu) => {
                            return (
                              <li key={menu.id}>
                                <Link to={"/" + menu.url}>{menu.name}</Link>
                              </li>
                            );
                          })
                        : null}
                        <li>
                           <Link to={"/order-detail"}>Track Order</Link>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>store information</h4>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-list">
                      <li>
                        <i className="fa fa-building" />
                        <span style={{fontWeight: 600}}>{this.state.companyName} &nbsp;<span style={{fontSize: "10px"}}>{this.state.companyRegistrationNo}</span></span>
                      </li>
                      <li>
                        <i className="fa fa-map-marker" />
                        {this.state.address1} {this.state.address2}
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        Call Us: {this.state.officeno ? <a href={"tel:" + this.state.officeno}>{this.state.officeno}</a> : null}
                       { this.state.officeno.length > 0 && this.state.mobileno.length > 0 ? (
                              <span>
                                <br/>
                                / {" "}
                              </span>
                            ) : null}
                        {this.state.mobileno ? (

                            <a href={"tel:" + this.state.mobileno}>{this.state.mobileno}</a>
                        ) : (
                          ""
                        )}
                      </li>
                      <li>
                        <i className="fa fa-envelope-o" />
                        Email Us: <span style={{ textTransform: "lowercase" }}>{this.state.email}</span>
                      </li>
                      {this.state.faxno ? (
                        <li>
                          <i className="fa fa-fax" />
                          Fax: {this.state.faxno}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="sub-title1">
                  {/* <div className="footer-title">
                    <h4>payment options</h4>
                  </div> */}
                  <div className="footer-contant">
                    {/* <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/logo_harimaufresh_payment_method_3rows.jpg?` + this.state.nowTimeString}
                      alt=""
                      style={{ width: "100%", height: "auto"}}
                    /> */}
                    {/* <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                      alt=""
                      style={{ width: "45px", height: "auto" }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        
        <div className="container"> 
          <ul>
          {facebook || instagram || tiktok || tiktok || flickr || twitter ? (
          <div>
          <li>
            <h4><b>
             Follow Us
             </b></h4>
          </li>
          </div>
          ) : null}
                      
          {facebook ? (
          <li className="onhover-div pl-0 mr-1">
          <a href={facebook} target="__blank">
            <img src={"/assets/images/social/fb.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}

          {instagram ? (
          <li className="onhover-div pl-0 mr-1">
          <a href={instagram} target="__blank">
            <img src={"/assets/images/social/ig.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}
          {tiktok ? (
          <li className="onhover-div pl-0 mr-1">            
          <a href={tiktok} target="__blank">
            <img src={"/assets/images/social/tiktok.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}
          
          {youtube ?(
          <li className="onhover-div pl-0 mr-1">            
          <a href={youtube} target="__blank">
            <img src={"/assets/images/social/youtube.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}

          {twitter ? (
          <li className="onhover-div pl-0 mr-1">            
          <a href={twitter} target="__blank">
            <img src={"/assets/images/social/twitter.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}

          {flickr ? (
          <li className="onhover-div pl-0 mr-1">
          <a href={flickr} target="__blank">
            <img src={"/assets/images/social/flickr.png?" + this.state.nowTimeString} style={{width:30,height:30}}></img>
          </a>
          </li>
          ) : null}

          </ul>
  
        </div>
        
        <div className="sub-footer">
          <div className="container">
          <hr></hr>
            <div className="footer-end row">

                <p className="col-md-6 col-sm-12">
                  <i className="fa fa-copyright" aria-hidden="true" /> {this.state.currentYear} HarimauFresh. All rights reserved.
                </p>

                <p className="col-md-6 col-sm-12">
                  <a id="antinternet" target="_blank" href="https://ant-internet.com" className="c-pointer">
                    Design and Development by Ant Internet Sdn Bhd
                  </a>
                </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
