import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./common/index.scss?v=1";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import TrendingProducts from "../components/home/products";
import SpecialProducts from "../components/home/special-products"
import Modal from "react-responsive-modal";
import Categories from "../components/home/categories";
import ShippingModal from "../components/home/shippingModal";
import moment from "moment";
import "slick-carousel/slick/slick.css?v=1";
import "slick-carousel/slick/slick-theme.css?v=1";
import "./custom-slick.css?v=1";
// Import custom components
import { svgFreeShipping, svgservice, svgoffer } from "../services/script";
import Cookies from "universal-cookie";
import SelectedTrendingProducts from "./home/selected-trending-products";
import SelectedCategoryProducts from "./home/selected-category-products";

const cookies = new Cookies();
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addressModal: false,
      nowTimeString: '',
    };
    if (props.referral) {
      const cookies = new Cookies();
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      cookies.set("partner-referral", props.referral, { path: "/", expires: expiryDate });
      localStorage.setItem("referral", JSON.stringify({ code: props.referral, name: props.referral, partner: true }));
    }
  }

  async componentWillMount() {
    document.getElementById("BODY").style.touchAction = "manipulation";
    let date = new Date();
    this.setState({ nowTimeString: Date.parse(date) });
  }

  async componentDidMount() {
    if (!this.props.isLoading) {
      // let hvPopBefore = await localStorage.getItem("popUp");
      let hvPopBefore = true;
      if (!hvPopBefore) {
        this.setPopUpToStorage();
      } else {
        let popDateTime = JSON.parse(hvPopBefore);
        if (new Date(moment(popDateTime).add(1, "days")) < new Date()) {
          this.setPopUpToStorage();
        }
        let ship = JSON.parse(localStorage.getItem("shipping"));
        if (ship === null) {
          this.setState({
            addressModal: true
          })
        }
      }
    }
  }

  setPopUpToStorage() {
    this.setState({ open: true }, () => {
      localStorage.setItem("popUp", JSON.stringify(new Date()));
    });
  }

  componentWillUnmount() {
    document.getElementById("BODY").style.touchAction = null;
  }

  onCloseModal = () => {
    this.setState({ open: false });
    let ship = JSON.parse(localStorage.getItem("shipping"));
    if (ship === null) {
      this.setState({
        addressModal: true
      })
    }
  };

  render() {
    const { bannerImg, history, isLoading } = this.props;
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = websiteTitle;
    var seoDesc = "Order freshly picked groceries, delivered straight to your home or workplace!";
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} />
          <meta property="og:title" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={process.env.BASE_URL} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={seoDesc} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDesc} />
          <meta name="twitter:image" content={seoImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta itemprop="description" property="description" content={seoDesc} />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{seoTitle}</title>
        </Helmet>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body" style={modalBox}>
                <h2 style={{ marginBottom: 10 }}>Welcome</h2>
                <br />
                <br />
                <span style={infoText}>
                  Our delivery is freshly from Harimau Mart, Shah Alam. The delivery rate will calculate by distance.
                </span>
                <br />
                <br />
                <span>Please refer to Shipping Information for delivery details.</span>
                <br />
                <Link to="/shipping-information" className="active">
                  Shipping Information
                </Link>
              </div>
              <br />
              <button className="btn btn-solid" onClick={this.onCloseModal} style={closeBtn}>
                Ok
              </button>
            </div>
          </div>
        </Modal>
        {/* {this.state.addressModal === true ? <ShippingModal></ShippingModal> : null} */}
        <section className="p-0">
          <Slider className="slide-1 home-slider" autoplay dots>
            {bannerImg && bannerImg.length > 0 ? (
              bannerImg.map((img) => {
                return (
                  <img src={img.filename + '?' + this.state.nowTimeString} />
                  // <div key={img.id}>
                  //   <div
                  //     className="home home39 text-center"
                  //     style={{
                  //       backgroundImage: `url(${img.filename}),url(${process.env.PUBLIC_URL}/assets/images/home-banner/banner-no-image.jpg)`,
                  //     }}>
                  //     <div className="container">
                  //       <div className="row">
                  //         <div className="col">
                  //           <div className="slider-contain">{/* can put text here... */}</div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                );
              })
            ) : (
                <div>
                  <div className="home home39 text-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home-banner/banner-no-image.jpg)` }}>
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <div className="slider-contain"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </Slider>
        </section>
        {/*collection banner layout*/}
        <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                    <div className="media-body">
                      {cookies.get('partner-referral') ?
                        <>
                          <h4>Free Delivery</h4>
                          <p>Collect via MyHub</p>
                        </>
                        : <>
                          <h4>Free Delivery</h4>
                          <p>With Purchase More Than RM200</p>
                        </>}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                    <div className="media-body">
                      <h4>Great Deal, High Value!</h4>
                      <p>Direct sourcing from farm</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                    <div className="media-body">
                    {cookies.get('partner-referral') ?
                        <>
                          <h4>Flexible Pick Up Time</h4>
                          <p>Order And Collect Via MyHub</p>
                        </>
                        : <>
                          <h4>Dedicated Sales Coordinator</h4>
                          <p>Assist Your Shopping Experience</p>
                        </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*collection banner layout end*/}
        {!isLoading ? (
          <>
            <SelectedCategoryProducts highlightTitle="KIDS " title="& BABY" subtitle="" history={history} />
            <TrendingProducts highlightTitle="SHOCKING" title="SALE" subtitle="" history={history} />
            <section style={{paddingTop:0}}> 
              <div className="container featured-banner-container">
                <div className="row">
                  <div className="col-sm-6 col-12 banner-col">
                    <a href="/" style={{pointerEvents:'none', cursor:'default'}}>
                      <img width='100%' src={`${process.env.PUBLIC_URL}/assets/images/featured-banner/featured-banner-one.png`}>
                      </img>
                    </a>
                  </div>
                  <div className="col-sm-6 col-12 banner-col">
                    <a href="/" style={{pointerEvents:'none', cursor:'default'}}>
                      <img width='100%' src={`${process.env.PUBLIC_URL}/assets/images/featured-banner/featured-banner-two.png`}>
                      </img>
                    </a>
                  </div>
                  <div className="col-sm-6 col-12 banner-col">
                    <a href="/" style={{pointerEvents:'none', cursor:'default'}}>
                      <img width='100%' src={`${process.env.PUBLIC_URL}/assets/images/featured-banner/featured-banner-three.png`}>
                      </img>
                    </a>
                  </div>
                  <div className="col-sm-6 col-12 banner-col">
                    <a href="/" style={{pointerEvents:'none', cursor:'default'}}>
                      <img width='100%' src={`${process.env.PUBLIC_URL}/assets/images/featured-banner/featured-banner-four.png`}>
                      </img>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <SpecialProducts highlightTitle="RECOMMENDED" title=" FOR YOU" subtitle="" history={history} />
            {/* <Categories title="TRENDING CATEGORIES" subtitle="" subcategoryTitle="OTHER CATEGORIES" history={history} /> */}
            <SelectedTrendingProducts highlightTitle="FRESH" title="&FROZEN" subtitle="" history={history} />
          </>
        ) : null}
      </div>
    );
  }
}

const closeBtn = {
  width: "100px",
  alignSelf: "center",
};

const infoText = {
  fontSize: 17,
  marginVertical: 10,
};

const modalBox = {
  padding: 30,
  textAlign: "center",
  borderWidth: 1,
};

export default Home;
