import React, {Component} from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";

class PageMaintenance  extends Component {

    constructor (props) {
        super (props)

    }

    render (){

        return (
            <div>
                <Breadcrumb title={'Maintenance Page'}/>

                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h2>We'll Be Right Back</h2>
                                    <h4>Sorry, we are down for maintenance but will be back in no time!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default PageMaintenance